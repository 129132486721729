import React, {useEffect } from 'react'
import { useStateValue } from "./../StateProvider";
import './App.css';
import { CssBaseline, createMuiTheme, ThemeProvider } from '@material-ui/core';
import Home from '../pages/Home'
import Login from '../pages/Login'
import { frFR, /*enEN*/ } from '@material-ui/core/locale';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#333996",
      light: '#3c44b126'
    },
    secondary: {
      main: "#f83245",
      light: '#f8324526'
    },
    background: {
      default: "#f4f5fd"
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        transform: 'translateZ(0)'
      }
    }
  },
  props: {
    MuiIconButton: {
      disableRipple: true
    }
  }
}, frFR)
//frFR


function App() {
  const [{isAuthenticated,missingRefresh,base_url}, dispatch] = useStateValue();


  useEffect(()=>{
    if (missingRefresh!==0 && isAuthenticated!==null){
      console.log(missingRefresh,isAuthenticated)
    const ref_token = localStorage.getItem('refresh_token')
    const bod = { "refresh": JSON.parse(ref_token) }
    fetch(base_url + "auth/token/refresh/", {
      method: "POST",
      headers: {
        "Content-Type": `application/json`
      },
      body: JSON.stringify(bod)
    }
    )
      .then(res => {
        if (res.ok) {return res.json()}
        else{
          dispatch({type: "LOGOUT"})
          return;
        }})
      .then(resJson =>  dispatch({type: "REFRESH_TOKEN", payload: resJson}))  
      }
    },[dispatch,base_url,missingRefresh,isAuthenticated])

  useEffect(() => {
    if (localStorage.getItem('access_token')!==null){
    const access_token = localStorage.getItem('access_token')
    const bod = { "token": JSON.parse(access_token) }
    fetch(base_url + "auth/token/verify/", {
      method: "POST",
      headers: {
        "Content-Type": `application/json`
      },
      body: JSON.stringify(bod)
    }
    )
      .then(res => {
        if (res.ok) {
          dispatch({type: "AUTH_SUCCESS"});
        } else {dispatch({type: "NEED_REFRESH"})}
      })
    }
    },[dispatch,base_url,missingRefresh])


  return (
    <ThemeProvider theme={theme}>
      {!isAuthenticated && <Login />}
      {isAuthenticated && <Home />}
      <CssBaseline />
    </ThemeProvider>
  );
}

export default App;

/*
DELETE FROM public.factor_limithistory
	WHERE 1=1;

DELETE FROM public.factor_client
	WHERE 1=1;

DELETE FROM public.factor_reconciliation 
	WHERE 1=1;
	
DELETE FROM public.factor_transaction 
	WHERE 1=1;

DELETE FROM public.factor_invoice 
	WHERE 1=1;

DELETE FROM public.factor_rebate 
  WHERE 1=1;
  */

  /*
  SELECT [InvoiceId]
      ,[ClientCode]
      ,[ClientName]
      ,[InvoiceNumber]
	  ,InvoiceDate
      ,[OriginalAmount]
	  ,[DueDate]
      ,[OriginalCurrencyBalance]
      ,[PaymentDate]
  FROM [SMART_Amaris].[BI_Finance].[FinanceInvoice]
  where Company='Portalia'  and IsDisabled=0 and 
  (ClientCode like 'C%' or ClientCode like 'F%') and
  (PaymentDate like '2020-10%' or OriginalCurrencyBalance !=0)
  */