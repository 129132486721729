import React, { useState } from 'react'
import { useStateValue } from "../../StateProvider";
import * as transactionService from "../../services/TransactionService";
import {DialogActions,makeStyles} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import IconButton from '@material-ui/core/IconButton';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import EditIcon from '@material-ui/icons/Edit';
import BuyMovement from "./BuyMovement";
import SellMovement from "./SellMovement";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles(theme => ({
  mouvements: {
      padding: theme.spacing(1),
      display:'flex',
      alignItems: 'top',

  },
  actionButtons:{
    backgroundColor:'white',
        '&hover':{
            backgroundColor: 'gray'},
      },
      actionButtons__validate:{
          color: 'green !important',
          '&hover':
          {backgroundColor:'#9CAE88 !important'}
        },
        actionButtons__cancel:{
            color: 'red !important'
          },
       actionButtons__add :{
        color:  'green !important',
        '&hover': {backgroundColor: '#9CAE88 !important'}
      },
      actionButtons__remove: {
        color: 'red !important'
      }
}))

export default  function AddTransaction({addOrEdit}){
  const [{isTransactionSubmitting,transaction }, dispatch] = useStateValue();
  const classes = useStyles();
  const [achat,setAchat] = useState(transaction.achat)
  const [vente,setVente] = useState(transaction.vente)

  const onSubmit = () => {
    dispatch({type: "ADD_TRANSACTION_REQUEST"})
    addOrEdit()
  }



    return (
        <>

        <DialogActions className={classes.actionButtons}>
        {achat?.length > 1 && <IconButton  className={classes.actionButtons__remove} onClick={() => {
          dispatch({type:'REMOVE_MOV', movId:achat[achat.length-1].id, operation:'achat'})
          transactionService.removeMovement(achat,setAchat)}
          }><RemoveIcon/></IconButton>}
        {(achat?.length>0 && vente?.length===0) &&  <IconButton className={classes.actionButtons__add} onClick={() => {
        dispatch({type:'ADD_MOV',operation:'achat', lot:0})
        transactionService.addMovement(achat,setAchat)}
      }
        ><AddIcon/></IconButton>}
        {vente?.length > 1 && <IconButton className={classes.actionButtons__remove}  onClick={() => {
          dispatch({type:'REMOVE_MOV', movId:vente[vente.length-1].id, operation:'vente'})
          transactionService.removeMovement(vente,setVente)}
         } ><RemoveIcon/></IconButton>} 
        {(vente?.length>0) &&   <IconButton className={classes.actionButtons__add}  onClick={() =>{
          dispatch({type:'ADD_MOV',operation:'vente', lot:0})
        transactionService.addMovement(vente,setVente)}
                }><AddIcon/></IconButton>}
        </DialogActions>
        <div className={classes.mouvements}>
{vente?.length > 0 && vente.map(m => (<SellMovement key={m.id} operationType={achat.length>0?'Transformation':'Vente'} recordForEdit={m} />))}
{vente?.length > 0 && achat?.length > 0 && (<NavigateNextIcon fontSize="large" />)}
{achat?.length > 0 && achat.map(m => (<BuyMovement key={m.id}  operationType={vente.length>0?'Transformation':'Achat'} recordForEdit={m} />))}

          </div>
        <DialogActions className={classes.actionButtons}>
          <IconButton className={classes.actionButtons__validate} onClick={onSubmit}  disabled={isTransactionSubmitting?true:false} >{transaction.transactionId?<EditIcon/>:<ShoppingCartIcon/>}</IconButton>
        </DialogActions>
        </>
    );
  }