import React, { useEffect, useContext } from 'react'
import { useStateValue } from "../../StateProvider";
import { LanguageContext } from '../../LanguageProvider';
import Controls from "../../components/controls/Controls";
import { useForm } from '../../components/components/useForm';
import {makeStyles} from '@material-ui/core';
import * as transactionService from "../../services/TransactionService";

const useStyles = makeStyles(theme => ({
    movement:{
        display: 'flex',
        flexDirection: 'column',
        minWidth:'200px',
        padding: '10px',
        margin:'5px',
        border: '1px solid grey',
        borderRadius: '10px',
        '&hover':{
            border: '1px solid #9CAE88'}
      }
  }))

const initialFValues = {
    id: 0,
    product: '',
    quantity: '',
    unit: '',
    measureUnit: '',
    dlc:null,
    dluo: null,
    operationDate: null,
    number:'',
    thirdparty: '',
}





export default function SellMovement(props) {
    const { d } = useContext(LanguageContext);
    const classes = useStyles();
    const { recordForEdit,operationType } = props
    const [{ tiers,transactions },dispatch] = useStateValue();



    const thirdparties = tiers.slice().map(t => (t.name))


    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const lotList = transactionService.QttperLot(transactions, 'lot', 0, 0, 0).map(c => ({ lotId: c.lot.id, number: c.lot.numero,unit : c.lot.unite, product:c.lot.product, measureUnit:c.lot.unitemesure, remaining: c.remaining})).filter(c=>c.remaining!==0)

    const {
        values,
        setValues,
        errors,
        handleInputChange,
    } = useForm(initialFValues, true, validate);



    useEffect(() => {
        if (recordForEdit != null){
            setValues({
                ...recordForEdit
            })
       // if (operationType==="Transformation"){setValues({...values,'thirdparty':'stock'})}
    }}, [recordForEdit, setValues])


    useEffect(()=>{
        if (values.id>0){
  dispatch({type:'EDIT_MOV', payload:values, operation:'vente'})
    }},[values,dispatch])


    return (
        <div className={classes.movement}>
            <Controls.Lots
                label={d.lotnumber}
                name="number"
                setValues={setValues}
                options={lotList}
                value={recordForEdit.number.concat(' - ', recordForEdit.product)}
                error={errors.number}
                values={values}
            />
            <br/>
            <Controls.Input
                label={d.product}
                name="product"
                value={values.product}
                error={errors.product}
                disabled={true}
                onChange={handleInputChange}
                shrink={true}
            />
<br/>
<Controls.Input
               label={d.unit}
               name="measureUnit"
               value={values.unit}
               error={errors.unit}
               disabled={true}
               onChange={handleInputChange}
               shrink={true}
            />
<br/>
<Controls.Input
               label={d.measureUnit}
               name="measureUnit"
               value={values.measureUnit}
               error={errors.measureUnit}
               disabled={true}
               onChange={handleInputChange}
               shrink={true}
            />
<br/>
            <Controls.Input
                label={d.quantity}
                name="quantity"
                value={values.quantity}
                error={errors.quantity}
                disabled={false}
                onChange={handleInputChange}
                shrink={true}
            />
            <br/>

{(operationType!=='Transformation'&&  recordForEdit.id===1) && (<>
<Controls.Autocomplete
                label={d.thirdparty}
                name="thirdparty"
                setValues={setValues}
                options={thirdparties}
                value={values.thirdparty}
                error={errors.thirdparty}
                values={values}
            />
            <br/>
            </>)
}
{(recordForEdit.id===1 || (recordForEdit.id===2 && operationType==='Transformation')) && (<>
 <Controls.DatePicker
                name="operationDate"
                label={d.operationDate}
                value={values.operationDate}
                onChange={handleInputChange}
            />

</>)
 }
</div>
    )
}
