export function login(base_url, data, dispatch){
        fetch(base_url + "auth/login/", {
          method: "post",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            username: data.username,
            password: data.password
          })
        })
          .then(res => {
            if (res.ok) {
              return res.json();
            }
            throw res;
          })
          .then(resJson => {
            dispatch({
                type: "LOGIN_SUCCESS",
                payload: resJson
            })
          })
          .catch(error => {
            console.log(error)
            dispatch({
              type: "LOGIN_FAILED"
            })
          });
      };

export function refreshToken(base_url,dispatch){
        const ref_token = localStorage.getItem('refresh_token')
        const bod = { "refresh": JSON.parse(ref_token) }
        fetch(base_url + "/auth/token/refresh/", {
          method: "POST",
          headers: {
            "Content-Type": `application/json`
          },
          body: JSON.stringify(bod)
        }
        )
          .then(res => {
            if (res.ok) {
              return res.json();
            } else { }
          })
          .then(resJson => {
            console.log(resJson);
            dispatch({
              type: "REFRESH",
              payload: resJson
            });
          })
      }

export   function postdata(base_url,object,objectid=null,input,access_token){

        const suffix_url = objectid?objectid+ '/': ''
    
        return fetch(base_url+ "api/" + object + "/" + suffix_url, {
          method: objectid?"PATCH":"POST",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": `application/json`, 
          },
          body: JSON.stringify(input),
        })
          .then(res => res.json())
      }

export function getdata(base_url,object, access_token, filter){
  return fetch(base_url + 'api/' + object + filter, 
        { headers: { Authorization: `Bearer ${access_token}` },} 
      )
          .then(res => res.json())
          .then(data => data.results)
      }

      export   function bulkcreatedata(base_url,object,input,access_token){
        
        return fetch(base_url+ "api/bulk" + object + "/" , {
          method: "POST",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": `application/json`, 
          },
          body: JSON.stringify(input),
        })
          .then(res => res.json())
      }

      export   function bulkupdatedata(base_url,object,input,access_token){
        
        return fetch(base_url+ "api/bulk" + object + "/", {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": `application/json`, 
          },
          body: JSON.stringify(input),
        })
          .then(res => res.json())
      }
