import React, { useState, createContext, useContext } from 'react';

import { languageOptions, dictionaryList } from './languages';

// create the language context with default selected language
export const LanguageContext = createContext({
  userLanguage: 'FR',
  d: dictionaryList.fr
});

// it provides the language context to app
export function LanguageProvider({ children }) {
  const [userLanguage, setUserLanguage] = useState('FR');

  const provider = {
    userLanguage,
    d: dictionaryList[userLanguage],
    userLanguageChange: selected => {
      const newLanguage = languageOptions[selected] ? selected : 'FR'
      setUserLanguage(newLanguage);
      window.localStorage.setItem('rcml-lang', newLanguage);
    }
  };

  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  );
};

// get text according to id & current language
export function Text({ tid }) {
  const languageContext = useContext(LanguageContext);

  return languageContext.d[tid] || tid;
};
