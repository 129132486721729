import React from 'react'
import { FormControl, TextField, FormHelperText } from '@material-ui/core';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';

export default function Lots(props) {

  const { label, error = null, options, setValues, values, style, value } = props;

  //useEffect(() => {setValues}, [input])
  const onchangenew = (event, input) => {
    //console.log( input, values)
    if (input !== null) {
      setValues({
        ...values,
        'lotId': input.lotId,
        'number':input.number,
        'unit': input.unit,
        'product': input.product.name,
        'measureUnit':input.measureUnit
      })
  }
  }


  return (
    <FormControl variant="outlined"
      {...(error && { error: true })}>

      <MuiAutocomplete
      style={style}
        options={options}
        defaultValue={value}
        //value={values.number.concat(' - ', values.product)}
        getOptionLabel={option => (option.number?option.number.concat(' - ', option.product.name, ' (', option.remaining,')'):value)}
        getOptionSelected={(option, value) =>  option?.number?.concat(' - ', option.product.name, ' (', option.remaining,')') === value?.number?.concat(' - ', value.product.name, ' (', value.remaining,')')}
        filterSelectedOptions                     
        onChange={onchangenew}
        renderInput={(params) => <TextField {...params} label={label}  variant="outlined"  />}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}