import React, {useEffect } from 'react'
import { useStateValue } from "../StateProvider";
import { makeStyles } from '@material-ui/core';
import Header from "../components/components/Header";
import References from "./References/References";

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import * as backend from "../services/backend";


const useStyles = makeStyles({
  global: {
    display:'flex',
    flexDirection:'row',
    height: '100%',
    minHeight : '100vh',
  //  fontFamily: 'Avenir Next LT Pro light',
  },
  
  appMain: {
    display:'flex',
    flexDirection:'column',
    flex:'1',
    height: '100%',
    minHeight : '100vh',
  }
})

function Home() {
  const classes = useStyles();
  const [{base_url,access_token}, dispatch] = useStateValue();

  useEffect(() =>{
    backend.getdata(base_url,'lot',access_token,"?limit=1000")
    .then(c=>dispatch({type:"FETCH_LOT_SUCCESS", payload:c}))
},[base_url,dispatch,access_token])  

  useEffect(() =>{
    backend.getdata(base_url,'transaction',access_token,`?limit=10000`)
    .then(c=>dispatch({type:"FETCH_TRANSACTION_SUCCESS", payload:c}))
},[base_url,dispatch,access_token])  

  useEffect(() =>{
    backend.getdata(base_url,'tier',access_token,`?limit=10000`)
    .then(c=>dispatch({type:"FETCH_TIER_SUCCESS", payload:c}))
},[base_url,dispatch,access_token])  

useEffect(() =>{
  backend.getdata(base_url,'product',access_token,`?limit=10000`)
  .then(c=>dispatch({type:"FETCH_PRODUCT_SUCCESS", payload:c}))
},[base_url,dispatch,access_token])  



  return (

     <Router>
     <div className={classes.global}>
        <div className={classes.appMain}>  
          <Header />
          <Switch>
          <Route path="/references"><References/></Route>
          <Route path="/"><References/></Route>
          </Switch>
        </div>
        </div>
        </Router>
  );
}

export default Home;

/*
DELETE FROM public.factor_limithistory
	WHERE 1=1;

DELETE FROM public.factor_client
	WHERE 1=1;

DELETE FROM public.factor_reconciliation 
	WHERE 1=1;
	
DELETE FROM public.factor_transaction 
	WHERE 1=1;

DELETE FROM public.factor_invoice 
	WHERE 1=1;

DELETE FROM public.factor_aging 
	WHERE 1=1;

DELETE FROM public.factor_rebate 
  WHERE 1=1;
  */

  /*
  SELECT [InvoiceId]
      ,[ClientCode]
      ,[ClientName]
      ,[InvoiceNumber]
	  ,InvoiceDate
      ,[OriginalAmount]
	  ,[DueDate]
      ,[OriginalCurrencyBalance]
      ,[PaymentDate]
  FROM [SMART_Amaris].[BI_Finance].[FinanceInvoice]
  where Company='Portalia'  and IsDisabled=0 and 
  (ClientCode like 'C%' or ClientCode like 'F%') and
  (PaymentDate like '2020-10%' or OriginalCurrencyBalance !=0)
  */