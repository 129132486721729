import React from 'react';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';



function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      suffix="€"
    />
  );
}

export default function InputCurrency(props) {
    const { name, label, value,error=null, onChange,shrink, ...other } = props;
  return (
      <TextField
        variant="outlined"
        label={label}
        value={value}
        onChange={onChange}
        name={name}
        {...other}
        {...(error && {error:true,helperText:error})}
        InputProps={{
          inputComponent: NumberFormatCustom,
        }}
      />

  );
}