import React from "react";
import { useStateValue } from "../StateProvider";
import { useForm, Form } from '../components/components/useForm';
import { Paper, makeStyles,Grid } from '@material-ui/core';
import Controls from "../components/controls/Controls";
import Logo2 from '../Logo2.png'

const useStyles = makeStyles({
  global: {
    display:'flex',
    alignContent:'center',
    padding: '10%',
    borderRadius: '5%',
    justifyContent: 'space-around',
  },
  pageContent: {

    display: 'flex',
    maxWidth:  '400px',
    justifyContent: 'space-between',
        '& img':{
    maxWidth:  '400px',
    height: '300px',
},
  },
})

export const Login = () => {
  const [{base_url},dispatch] = useStateValue();
  const classes = useStyles();
  
  const initialFValues = {
    username: "",
    password: "",
    isSubmitting: false,
    errorMessage: null
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if (fieldValues === values)
        return Object.values(temp).every(x => x === "")
}


  const {
    values,
    setValues,
    errors,
    handleInputChange,
} = useForm(initialFValues, true,validate );

  const handleSubmit = event => {
    event.preventDefault();
    setValues({
      ...values,
      isSubmitting: true,
      errorMessage: null
    });
    fetch(base_url + "auth/login/", {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        username: values.username,
        password: values.password
      })
    })
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw res;
      })
      .then(resJson => {
        dispatch({
            type: "LOGIN",
            payload: resJson
        })
      })
      .catch(error => {
        setValues({
          ...values,
          isSubmitting: false,
          errorMessage: error.message || error.statusText
        });
      });
  };

  return (
    <div className={classes.global}>
    <Paper className={classes.pageContent}>
      <Grid container>
      <Grid item xs={12}>
      <img src={Logo2} alt='CDLG' />
      </Grid>
      <Grid item xs={12}>
        <Form onSubmit={handleSubmit}>
            <Grid container>
            <Grid item xs={12}>
                            <Controls.Input
                                label={'Username'}
                                name="username"
                                value={values.username}
                                onChange={handleInputChange}
                            /></Grid>
                                  <Grid item xs={12}>
                             <Controls.Input
                                type='password'
                                label={'Password'}
                                name="password"
                                value={values.password}
                                onChange={handleInputChange}
                                autoComplete="on"
                            /></Grid>
                            <br/>
                            <br/>
                        <Controls.Button
                            type="submit"
                            text={"Login"} 
                            disabled={values.isSubmitting}
                            />
                            </Grid>
                            </Form>
                            </Grid>
                            </Grid>
    </Paper>
    </div>
  );
};

export default Login;
