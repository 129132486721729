import React from 'react'
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { fr } from 'date-fns/locale'


export default function RangeData(props) {
    const { ranges, onChange } = props

    return (
        <DateRange
        ranges={ranges}
        onChange={onChange}
        dateDisplayFormat={'d MMM yyyy'}
        showDateDisplay={true}
        locale={fr}
        />
    )
}

/*

const [startDate, setStartDate] = useState(new Date())
const [endDate, setEndDate] = useState(new Date())

const selectionRange = {
    startDate: startDate,
    endDate:endDate,
    key:"selection",
}

function handleSelect(ranges){
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate)
}
*/