import React, { useState } from 'react'
import { Table, TableHead, TableRow, TableCell, makeStyles, TablePagination, TableSortLabel, Checkbox } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    table: {
        marginTop: theme.spacing(3),
        '& thead th': {
            fontWeight: '600',
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.light,
        },
        '& tbody td': {
            fontWeight: '300',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth:'400px'
        },
        '& tbody td:hover': {
            textOverflow: 'clip',
            whiteSpace: 'preLine',
            // whiteSpace: 'normal',
            wordBreak: 'break-all'

        },
        '& tbody tr:hover': {
            backgroundColor: '#fffbf2',
            cursor: 'pointer',
        },
    },
}))

export default function useTable(records, headCells,filterFn, defaultOrder, defaultselected,needSelectAll) {

    const classes = useStyles();

    const pages = [100, 150, 200]
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(pages[page])
    const [order, setOrder] = useState()
    const [orderBy, setOrderBy] = useState(defaultOrder)
    const [selected, setSelected] = useState(defaultselected);
    const [open, setOpen] = useState([]);
    
    const TblContainer = props => (
        <Table className={classes.table}>
            {props.children}
        </Table>
    )


    const numSelected = selected.length
    const rowCount = records.length

    const TblHead = props => {
        
        const handleSortRequest = cellId => {
            const isAsc = orderBy === cellId && order === "asc";
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(cellId)
        }

        return (<TableHead>
            <TableRow>
        {needSelectAll===true?
            <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={handleSelectAllClick}
          />
        
        </TableCell>
          :
          <TableCell/>

          }
                    {headCells.map(headCell => (
                        
                        <TableCell key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            sortDirection={orderBy === headCell.id ? order : false}>
                            {headCell.disableSorting ? headCell.label :
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={() => { handleSortRequest(headCell.id) }}>
                                    {headCell.label}
                                </TableSortLabel>
                            }
                        </TableCell>))
                }
               
            </TableRow>
        </TableHead>)
    }


    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
          const newSelecteds = records.map((r) => r.id);
          setSelected(newSelecteds);
          return;
        }
        setSelected([]);
      };


      const handleOpen = (event, id) => {
        const openIndex = open.indexOf(id);
        let newOpen= [];
    
        if (openIndex === -1) {
            newOpen = newOpen.concat(open, id);
        } else if (openIndex === 0) {
            newOpen = newOpen.concat(open.slice(1));
        } else if (openIndex === open.length - 1) {
            newOpen = newOpen.concat(open.slice(0, -1));
        } else if (openIndex > 0) {
            newOpen = newOpen.concat(
            open.slice(0, openIndex),
            open.slice(openIndex + 1),
          );
        }
        setOpen(newOpen);
      };

      const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
    
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
          );
        }
        setSelected(newSelected);
      };
    
    const isSelected = (id) => selected.indexOf(id) !== -1;
    const isOpen = (id) => open.indexOf(id) !== -1;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0);
    }

    const TblPagination = () => (<TablePagination
        component="div"
        page={page}
        rowsPerPageOptions={pages}
        rowsPerPage={rowsPerPage}
        count={records.length}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
    />)

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    function getComparator(order, orderBy) {
       
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function descendingComparator(a, b, orderBy) {
   
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    const recordsAfterPagingAndSorting = () => {
        return stableSort(filterFn.fn(records), getComparator(order, orderBy))
            .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
    }

    return {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
        handleClick,
        isSelected,
        handleOpen,
        isOpen,
        handleChangePage

    }
}
