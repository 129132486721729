import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import Checkbox from "./Checkbox";
import DatePicker from "./DatePicker";
import Button from "./Button";
import ActionButton from "./ActionButton";
import Autocomplete from "./Autocomplete";
import RangeData from "./RangeData";
import RangePicker from "./RangePicker";
import InputCurrency from "./InputCurrency";
import Amt from "./Amt";
import Switch from "./Switch";
import Lots from "./Lots";
const Controls = {
    Input,
    RadioGroup,
    Select,
    Checkbox,
    DatePicker,
    Button,
    ActionButton,
    Autocomplete,
    RangeData,
    RangePicker,
    InputCurrency,
    Amt,
    Switch,
    Lots
}

export default Controls;