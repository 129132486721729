import React, { useEffect, useContext } from 'react'
import { useStateValue } from "../../StateProvider";
import { LanguageContext } from '../../LanguageProvider';
import Controls from "../../components/controls/Controls";
import { useForm } from '../../components/components/useForm';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    movement:{
        display: 'flex',
        flexDirection: 'column',
        minWidth:'200px',
        padding: '10px',
        margin:'5px',
        border: '1px solid grey',
        borderRadius: '10px',
        '&hover':{
            border: '1px solid #9CAE88'}
      }
  }))

const initialFValues = {
    id: 0,
    product: '',
    quantity: '',
    unit: '',
    measureUnit: '',
    dlc:null,
    dluo: null,
    operationDate: new Date(),
    number: '',
    thirdparty: '',
}



export default function BuyMovement(props) {
    const { d } = useContext(LanguageContext);
    const classes = useStyles();
    const { recordForEdit, operationType}= props
    const [{ products,tiers}, dispatch] = useStateValue();

    const measureUnits= [
        {id:'ml',title:'ml'}, 
        {id:'L', title:'L'}, 
        {id:'g', title:'g'}, 
        {id:'Kg', title:'Kg'}, 
        {id:'unité',title:'unité'},
    ]
    const productList = products.slice().map(p => (p.name))
    const thirdparties = tiers.slice().map(t => (t.name))


    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    // const ccodes = clients.map(c => ({ clientId: c.clientId, title: c.clientCode, load: c.legalName }))

    const {
        values,
        setValues,
        errors,
        handleInputChange,
    } = useForm(initialFValues, true, validate);



    useEffect(() => {
        if (recordForEdit != null){
            setValues({
                ...recordForEdit
            })
       // if (operationType==="Transformation"){setValues({...values,'thirdparty':'stock'})}
    }}, [recordForEdit, setValues])

    useEffect(()=>{
        if (values.id>0){
  dispatch({type:'EDIT_MOV', payload:values, operation:'achat'})
    }},[values,dispatch])


    return (
        <div className={classes.movement}>
            <Controls.Input
                label={d.lotnumber}
                name="number"
                value={values.number}
                error={errors.number}
                disabled={false}
                onChange={handleInputChange}
                shrink={true}
            />
<br/>
            <Controls.Autocomplete
                label={d.product}
                name="product"
                setValues={setValues}
                options={productList}
                value={values.product}
                error={errors.product}
                values={values}
            />

            <br/>

            <Controls.Input
                label={d.unit}
                name="unit"
                value={values.unit}
                error={errors.unit}
                disabled={false}
                onChange={handleInputChange}
                shrink={true}
            />
<br/>
            <Controls.Select
                name="measureUnit"
                label={d.measureUnit}
                value={values.measureUnit}
                options={measureUnits}
                onChange={handleInputChange}
                error={errors.entryType}
            />
<br/>
<Controls.Input
                label={d.quantity}
                name="quantity"
                value={values.quantity}
                error={errors.quantity}
                disabled={false}
                onChange={handleInputChange}
                shrink={true}
            />
            <br/>
            <Controls.DatePicker
                name="dlc"
                label={d.dlc}
                value={values.dlc}
                onChange={handleInputChange}
            />
<br/>
            <Controls.DatePicker
                name="dluo"
                label={d.dluo}
                value={values.dluo}
                onChange={handleInputChange}
            />
<br/>


{(operationType!=='Transformation' && recordForEdit.id===1 ) && (<>
<Controls.Autocomplete
                label={d.thirdparty}
                name="thirdparty"
                setValues={setValues}
                options={thirdparties}
                value={values.thirdparty}
                error={errors.thirdparty}
                values={values}
            />
            <br/>
            </>)}
            {recordForEdit.id===1 && operationType!=='Transformation' &&(<>
            <Controls.DatePicker
                name="operationDate"
                label={d.operationDate}
                value={values.operationDate}
                onChange={handleInputChange}
            />
</>)}
</div>
    )
}
