

import React from 'react'
import { DateRangePicker, START_DATE, END_DATE } from 'react-nice-dates'
import 'react-nice-dates/build/style.css'
import { fr } from 'date-fns/locale'

export default function RangePicker(props) {
    const { startDate, endDate,setStartDate,setEndDate} = props

    return (
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
          maximumDate={new Date()}
          minimumLength={1}
          format='dd MMM yyyy'
          locale={fr}
        >
          {({ startDateInputProps, endDateInputProps, focus }) => (
            <div className='date-range'>
              <input
                className={'input' + (focus === START_DATE ? ' -focused' : '')}
                {...startDateInputProps}
                placeholder='Start date'
              />
              <span className='date-range_arrow' />
              <input
                className={'input' + (focus === END_DATE ? ' -focused' : '')}
                {...endDateInputProps}
                placeholder='End date'
              />
            </div>
          )}
        </DateRangePicker>
      )
    }
