

import React, { useState, useEffect, useContext } from 'react'
import { useStateValue } from "../../StateProvider";
import { LanguageContext } from '../../LanguageProvider';
import AddTransaction from "./AddTransaction";
import { Paper, makeStyles, TableBody, TableRow, TableCell, Toolbar, InputAdornment,Breadcrumbs, Link, IconButton } from '@material-ui/core';
import useTable from "../../components/components/useTable";
import * as transactionService from "../../services/TransactionService";
import Controls from "../../components/controls/Controls";
import { Search } from "@material-ui/icons";

import Popup from "../../components/components/Popup";

import Notification from "../../components/components/Notification";
import ConfirmDialog from "../../components/components/ConfirmDialog";

import * as exportService from "../../services/exportService";
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TransformIcon from '@material-ui/icons/Transform';
import DeleteIcon from '@material-ui/icons/Delete';



const useStyles = makeStyles(theme => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),

    },
    toolbar: {
        justifyContent: 'space-around'
    },
    searchInput: {
        width: '30%',
        padding: '10px'
    },
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'red',
        maxWidth: 220,
        fontSize: 300,
    },
    secondLine: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    greenButton: {
        color: 'green',
        borderColor: 'green'
    },
    bread: {
        display: 'flex',
        alignItems: 'center'
    }
}))





export default function References() {
    const { d } = useContext(LanguageContext);
    const [{ transactions, access_token, homeDisplay, base_url,dateToYMD, transaction }, dispatch] = useStateValue();
    const classes = useStyles();
    const [records, setRecords] = useState([])
    const [headCells, setHeadCells] = useState([])
    const [reference, setReference] = useState({ product: { id: 0 }, unite: null, unitemesure: null })
    const [lot, setLot] = useState(0)
    const [thirdparty, setThirdparty] = useState(0)
    const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
    const [openPopup, setOpenPopup] = useState(false)
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })
    




    const initialValues = {
        groupByClient: false,
        displayDetails: false,
        displayEmpty: true,
    }
    const [values, setValues] = useState(initialValues);


    useEffect(() => {

        if (homeDisplay === 'references') {
            setHeadCells([
                { id: 'reference', numeric: false, label: d.references },
                { id: 'qttIn', numeric: false, label: d.in },
                { id: 'qttOut', numeric: false, label: d.out },
                { id: 'remaining', numeric: false, label: d.remaining },
            ])
            setRecords(transactionService.QttperLot(transactions, 'ref', thirdparty, lot, 0).filter(x=>((x.qttIn+x.qttOut)!==0) || values.displayEmpty===true))

        }
        else if
            (homeDisplay === 'lots') {
            setHeadCells([
                { id: 'numero', numeric: false, label: d.lots },
                { id: 'qttIn', numeric: false, label: d.in },
                { id: 'qttOut', numeric: false, label: d.out },
                { id: 'remaining', numeric: false, label: d.remaining },
            ])
            setRecords(transactionService.QttperLot(transactions, 'lot', thirdparty, lot, reference).filter(x=>((x.qttIn+x.qttOut)!==0) || values.displayEmpty===true))
        }
        else if
            (homeDisplay === 'movements') {
            setHeadCells([
                { id: 'operation', numeric: false, label: d.operation },
                { id: 'quantity', numeric: false, label: d.quantity },
                { id: 'thirdparty', numeric: false, label: d.thirdparty },
                { id: 'operationDate', numeric: false, label: d.operationDate },
                { id: 'disable', numeric: false, label: '' }
            ])

            setRecords(transactionService.QttperLot(transactions, 'movements', thirdparty, lot, reference))
        }
        else if
            (homeDisplay === 'thirdparty') {
            setHeadCells([
                { id: 'thirdparty', numeric: false, label: d.thirdparties },
                { id: 'qttIn', numeric: false, label: d.in },
                { id: 'qttOut', numeric: false, label: d.out },
            ])
            setRecords(transactionService.QttperLot(transactions, 'thirdparty', thirdparty, lot, reference))
        }
    }
        , [homeDisplay, transactions, values,d, lot, reference,thirdparty])


useEffect(()=>{
    if (openPopup===false){dispatch({type:'CLEAN_TRANSACTION'})}
},[openPopup,dispatch])

    const onDelete = item => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
        let i = transactions.findIndex(t=>t.id===item.transactionId)
        transactionService.addTransaction(transactionService.loadTransaction(transactions[i]), base_url, access_token,dateToYMD,false)
        .then(message => {
            if (typeof message.id === 'undefined') {
                dispatch({ type: 'POST_FAIL' })
                console.log(message)
                setNotify({ isOpen: true, message: d.error, type: 'error' })
            }
            else {
                setOpenPopup(false)
                dispatch({ type: 'DISABLE_TRANSACTION_SUCCESS', payload: message })
                dispatch({ type: 'CLEAN_TRANSACTION'})
                setNotify({isOpen: true, message: d.deletionsuccess, type: 'success'})
                }})}

        
    const {
        TblContainer,
        TblHead,
        recordsAfterPagingAndSorting,
        handleChangePage,
    } = useTable(records, headCells, filterFn, homeDisplay==='references'?'reference':homeDisplay==='lots'?'numero':homeDisplay==='thirdparty'?'':'operationDate', [], false);

    
    const handleClick = (item) =>{
        //console.log(item)
        if (homeDisplay==='references'){
            setReference({product:{id:item.product.id},unite:item.unite, unitemesure:item.unitemesure})
            values.groupByClient===false?dispatch({type:'HOMEDISPLAY',payload:'lots'}):dispatch({type:'HOMEDISPLAY',payload:'thirdparty'})}
        else if (homeDisplay==='lots'){
            setLot(item.lot.id)
            if (values.displayDetails===false){dispatch({type:'HOMEDISPLAY',payload:'thirdparty'})}else{dispatch({type:'HOMEDISPLAY',payload:'movements'})}}
        else if (homeDisplay==='thirdparty'){
            if (lot!==0){
                setThirdparty(item.tier)
                setValues({...values,"displayDetails": true})
                dispatch({type:'HOMEDISPLAY',payload:'movements'})
            }else{
                setThirdparty(item.tier)
                dispatch({type:'HOMEDISPLAY',payload:'lots'})}}
        else if (homeDisplay==='movements'){
            let i = transactions.findIndex(t=>t.id===item.transactionId)
            dispatch({type:'LOAD_TRANSACTION', payload:transactions[i]})
            setOpenPopup(true)
        }
        }
    



    const handleSearch = e => {
        let target = e.target;
        handleChangePage(e, 0)
        setFilterFn({
            fn: items => {
                if (target.value === "")
                    return items;
                else
                    if (homeDisplay==='references'){return items.filter(x => x.reference.toLowerCase().includes(target.value.toLowerCase().trim()))}
                    else if (homeDisplay==='lots'){return items.filter(x => x.numero.toLowerCase().includes(target.value.toLowerCase().trim())||x.reference.toLowerCase().includes(target.value.toLowerCase().trim()))}
                    else if (homeDisplay==='thirdparty'){return items.filter(x => x.thirdparty.toLowerCase().includes(target.value.toLowerCase().trim())||x.reference.toLowerCase().includes(target.value.toLowerCase().trim()))}
            }
        })
    }


    function addOrEdit() {
        dispatch({ type: 'POST_DATA' })
        transactionService.addTransaction(transaction, base_url, access_token,dateToYMD,true)
            .then(message => {
                if (typeof message.id === 'undefined') {
                    dispatch({ type: 'POST_FAIL' })
                    console.log(message)
                    setNotify({ isOpen: true, message: d.error, type: 'error' })
                }
                else {
                    setOpenPopup(false)

                    if (transaction.transactionId !== 0) {
                        dispatch({ type: 'EDIT_TRANSACTION_SUCCESS', payload: message })
                        dispatch({ type: 'CLEAN_TRANSACTION'})
                        setNotify({ isOpen: true, message: d.editSuccess, type: 'success' })
                    }
                    else {
                        dispatch({ type: 'ADD_TRANSACTION_SUCCESS', payload: message })
                        dispatch({ type: 'CLEAN_TRANSACTION'})
                        setNotify({ isOpen: true, message: d.addSuccess, type: 'success' })
                    }

                }
            })
    }



    const exportExcel = (data) => {
        let schema=[]
        headCells.forEach(h=>{
            let input= {'label':h['label'], 'id':h["id"]}
        schema= [...schema,input]
        })
        exportService.ExportCSV(data, schema, homeDisplay)
    }


    const handleInputChange = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
        if (name==='groupByClient' && value===true){
            dispatch({ type: "HOMEDISPLAY", payload: 'thirdparty' })}
        if (name==='groupByClient' && value===false){
            dispatch({ type: "HOMEDISPLAY", payload: 'lots' })}
        if (name==='displayDetails' && value===true){dispatch({ type: "HOMEDISPLAY", payload: 'movements' })}
        if (name==='displayDetails' && value===false){dispatch({ type: "HOMEDISPLAY", payload: 'thirdparty' })}
    }


    function frenchFormat(date) {
        date = new Date(date)
        var d = date.getDate();
        var m = date.getMonth()+1; //Month from 0 to 11
        var y = date.getFullYear();
        return (d <= 9 ? '0' + d : d) + '/' + (m <= 9 ? '0' + m : m) + '/' +y  ;
      }

    return (
        <>
            <Paper className={classes.pageContent}>
                <Toolbar className={classes.toolbar} >
                <Controls.Switch name="displayEmpty" label={d.displayEmpty} value={values.displayEmpty} onChange={handleInputChange} />

                    <Controls.Input
                        label={d.search + ' ' + d.references}
                        className={classes.searchInput}
                        InputProps={{
                            startAdornment: (<InputAdornment position="start">
                                <Search />
                            </InputAdornment>)
                        }}
                        onChange={handleSearch}
                    />
                    {(homeDisplay==='lots' ||(homeDisplay==='thirdparty' && lot===0))  && <Controls.Switch name="groupByClient" label={d.groupBy + ' ' + d.thirdparty} value={values.groupByClient} onChange={handleInputChange} />}
                    {(homeDisplay==='movements'  || (homeDisplay==='thirdparty' && lot!==0)) && <Controls.Switch name="displayDetails" label={d.displayDetails} value={values.displayDetails} onChange={handleInputChange} />}
                                   </Toolbar>
                <div className={classes.secondLine}>
                    <Controls.Button
                        text={d.add}
                        variant="outlined"
                        startIcon={<ShoppingCartIcon />}
                        className={classes.newButton}
                        onClick={() => { 
                            setOpenPopup(true)
                            dispatch({type:'ADD_MOV',operation:'achat', lot:0})}
                          } 
                    />
                                        <Controls.Button
                        text={d.remove}
                        variant="outlined"
                        startIcon={<ExitToAppIcon />}
                        className={classes.newButton}
                        onClick={() => { 
                            setOpenPopup(true)
                            dispatch({type:'ADD_MOV',operation:'vente', lot:lot})}
                          }
                    />
                    <Controls.Button
                        text={d.transform}
                        variant="outlined"
                        startIcon={<TransformIcon />}
                        className={classes.newButton}
                        onClick={() => { 
                            setOpenPopup(true)
                            dispatch({type:'ADD_MOV',operation:'achat', lot:0})
                            dispatch({type:'ADD_MOV',operation:'vente', lot:lot})
                          }}
                    />
                    <Controls.Button
                        text={d.excel}
                        variant="outlined"
                        startIcon={<GetAppOutlinedIcon />}
                        className={classes.greenButton}
                        onClick={() => exportExcel(records)}
                    />
                </div>
                <div className={classes.bread}>
                    {homeDisplay === 'movements' && <IconButton onClick={() => {
                        setThirdparty(0)
                        setValues({...values,"displayDetails": false})
                        dispatch({ type: "HOMEDISPLAY", payload: 'lots' })
                    }
                        } ><ArrowBackIosIcon /></IconButton>}
                    {homeDisplay === 'lots' && <IconButton onClick={() => {
                        setLot(0)
                        setThirdparty(0)
                        dispatch({ type: "HOMEDISPLAY", payload: 'references' })}} ><ArrowBackIosIcon /></IconButton>}
                    {homeDisplay === 'thirdparty' && <IconButton onClick={() => {
                        setLot(0)
                        setThirdparty(0)
                        dispatch({ type: "HOMEDISPLAY", payload: 'lots' })}} ><ArrowBackIosIcon /></IconButton>}

                    <Breadcrumbs separator={homeDisplay !== 'references' && <NavigateNextIcon fontSize="small" />}>
                        <Link color="inherit">
                            {(homeDisplay==='movements' && thirdparty===0) && records[0].reference + ' Lot: ' + records[0].numero}
                            {(homeDisplay==='movements'  && thirdparty!==0) && records[0].reference + ' - Lot: ' + records[0].numero + ' - Tiers: ' + records[0].thirdparty}
                            {(homeDisplay==='thirdparty' && lot===0 && thirdparty===0) && records[0].reference}                            
                            {(homeDisplay==='thirdparty' && lot!==0 && thirdparty===0) && records[0].reference + ' - Lot: ' + records[0].numero}
                            {(homeDisplay==='thirdparty' && lot!==0 && thirdparty!==0) && records[0].reference + ' - Lot: ' + records[0].numero + '- Tiers: ' + records[0].thirdparty}
                            {(homeDisplay=== 'lots') && records[0]?.reference}
                        </Link>
                    </Breadcrumbs>
                </div>

                <TblContainer>
                    <TblHead />
                    <TableBody>

                        {recordsAfterPagingAndSorting().map((item) => {
                            return (
                                
                                    <TableRow key={item.id} onClick={()=>handleClick(item)} >
                                        <TableCell />
                                        <TableCell>{homeDisplay === 'references' ? item.reference : homeDisplay === 'lots' ? item.numero : homeDisplay === 'thirdparty' ? item.thirdparty : item.operation}</TableCell>
                                        <TableCell>{homeDisplay === 'movements' ? item.quantity : item.qttIn}</TableCell>
                                        <TableCell>{homeDisplay === 'movements' ? item.thirdparty : item.qttOut}</TableCell>
                                        {homeDisplay !== 'thirdparty' ? <TableCell>{homeDisplay === 'movements' ? frenchFormat(item.operationDate) : item.remaining}</TableCell> : null}
                                        {homeDisplay==='movements' &&  
                                        <TableCell>
                                        <Controls.ActionButton
                                            color="secondary"
                                            onClick={() => {
                                                setConfirmDialog({
                                                    isOpen: true,
                                                    title: 'Voulez-vous supprimer cette transaction?',
                                                    subTitle: "Action irreversible (presque)",
                                                    onConfirm: () => { onDelete(item) }
                                                })
                                            }}>
                                            <DeleteIcon/>
                                        </Controls.ActionButton>
                                        </TableCell>}
                                    </TableRow>)
                        }
                        )
                        }
                    </TableBody>
                </TblContainer>
            </Paper>
            <Popup
                title={transaction.achat?.length>0?transaction.vente?.length>0?d.transform:d.add:d.remove}
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
                <AddTransaction
                    transaction={transaction}
                    addOrEdit={addOrEdit}
                    />
            </Popup>
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </>
    )
}
