import React from 'react'
import { FormControl,TextField, FormHelperText } from '@material-ui/core';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';

export default function Autocomplete(props) {

    const { name, label,error=null, options,setValues,values,style, value} = props;

//useEffect(() => {setValues}, [input])
    const onchangenew = (event, input)=>{
//        console.log(name,input, values)
        if (input!==null){
        setValues({
            ...values,
            [name]: input,
        })
    }}


    return (
        <FormControl variant="outlined"
        {...(error && {error:true})}>

            <MuiAutocomplete
                onChange={onchangenew}
                value={value}
                options={options}
                getOptionSelected={(opt, val) => opt.value === val.value}    
                renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
                style={style}
                />
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    )
}
