import React from 'react'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { fr /*,enGB*/ } from 'date-fns/locale'

export default function DatePicker(props) {

    const { name, label, value, onChange,maxDate,minDate } = props
    const convertToDefEventPara = (name, value) => ({
        target: {
            name,value
        }
    })

    //https://material-ui-pickers.dev/api/KeyboardDatePicker
//disableToolbar
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}  locale={fr}>
            <KeyboardDatePicker autoOk variant="inline" inputVariant="outlined"
                label={label}
                format="dd/MM/yyyy"
                name={name}
                value={value}
                onChange={date =>onChange(convertToDefEventPara(name,date))}
                maxDate={maxDate}
                minDate={minDate}
            />
        </MuiPickersUtilsProvider>
    )
}
