import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export function ExportCSV (liste, schema, filename){
    let file=[]
    liste.forEach(item => {
        var obj = {};
        schema.forEach(column => {
        let cname = column.label
        let cvalue = item[column.id]
        obj[cname]= cvalue
        })
        file.push(obj)
    })

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(file);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, filename + fileExtension);
}