import React from 'react'
import CurrencyFormat from "react-currency-format";

export default function Amt(props) {

    const { value, ...other } = props;
    return (
<CurrencyFormat value={Math.round(value*100)/100}
thousandSeparator={" "} 
displayType={"text"} 
suffix={"€"} 
{...other}
 />
    )
}
