import * as transactionService from "./services/TransactionService";

function dateToYMD(date) {
  date = new Date(date)
  console.log(date)
  var d = date.getDate();
  var m = date.getMonth()+1; //Month from 0 to 11
  var y = date.getFullYear();
  return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
}

export const initialState = {
  isAuthenticated: false,
  base_url: 
 'https://www.stock.chateaudelagabelle.fr/', 
 // 'http://127.0.0.1:8000/',
  /*   reference: {id:null,image: null, product:null,unite:null,unitemesure:null},*/
  user: null,
  missingRefresh:0,
  access_token: null,
  refresh_token: null,
  dateToYMD: dateToYMD,
  transactions: [],
  lots: [],
  products: [],
  tiers: [],
  isFetching: false,
  hasError: false,
  isTransactionSubmitting: false,
  TransactionHasError: false,
  homeDisplay: 'references',
  ismodification: null,
  counter: 0,
  transaction: {transactionId:0,achat:[],vente:[]},
};




const reducer = (state, action) => {
  console.log(action);
  switch (action.type) {
    //AUTHENTIFICATION
    case "LOGIN":
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      localStorage.setItem("access_token", JSON.stringify(action.payload.access_token));
      localStorage.setItem("refresh_token", JSON.stringify(action.payload.refresh_token));
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        access_token: action.payload.access_token,
        refresh_token: action.payload.refresh_token
      };
    case "LOGOUT":
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        access_token:null,
        refresh_token:null,
        user: null,
        missingRefresh:0
      };
      case "NEED_REFRESH":
        return {
          ...state,
          missingRefresh: 1,
        };
      case "REFRESH_TOKEN":
      localStorage.setItem("access_token", JSON.stringify(action.payload.access));
        return {
          ...state,
          missingRefresh: 0,
          access_token: action.payload.access,
        };
    case "AUTH_SUCCESS":
      const access_token = localStorage.getItem('access_token')
      const newuser = localStorage.getItem('user')
      return {
        ...state,
        isAuthenticated: true,
        user: JSON.parse(newuser),
        access_token: JSON.parse(access_token)
      };

    //FETCH transactions          
    case "FETCH_TRANSACTION_SUCCESS":
      return {
        ...state,
        isFetching: false,
        transactions: action.payload
      };
    //FETCH Lots
    case "FETCH_LOT_SUCCESS":

      return {
        ...state,
        isFetching: false,
        lots: action.payload,
      };
    //FETCH Tiers
    case "FETCH_TIER_SUCCESS":
      return {
        ...state,
        isFetching: false,
        tiers: action.payload
      };
    //FETCH references
    case "FETCH_REFERENCES_SUCCESS":
      return {
        ...state,
        references: action.payload
      };
    //FETCH Products          
    case "FETCH_PRODUCT_SUCCESS":
      return {
        ...state,
        isFetching: false,
        products: action.payload
      };

      
    //POST TRANSACTION
    case "POST_REQUEST":
      return {
        ...state,
        isTransactionSubmitting: true,
        transactionHasError: false,
      }
    case "TRANSACTION_FAILURE":
      return {
        ...state,
        isTransactionSubmitting: false,
        transactionHasError: true,
      }
    // Add Product
    case "ADD_TRANSACTION_SUCCESS":
      let NewLots = state.lots
      for (let m of action.payload.mouvements){
      let idz = NewLots.findIndex(l => l.id === m.lot.id)
      if(idz===-1){NewLots=[...NewLots,m.lot]}
      }
      return {
        ...state,
        isTransactionSubmitting: false,
        transactions: [action.payload, ...state.transactions],
        lots: NewLots
      }
    // Remove Product
    case "DISABLE_TRANSACTION_SUCCESS":
      const idy = state.transactions.findIndex(t => t.id === action.payload.id);
      const newTrans2 = Object.assign([], state.transactions);
      newTrans2.splice(idy, 1);
      return {
        ...state,
        isTransactionSubmitting: false,
        transactions: newTrans2,
      }
    // Transform Product
    case "TRANSFORM_PRODUCT_SUCCESS":
      var last = function last(array, n) {
        if (array == null) return void 0;
        if (n == null) return array[array.length - 1];
        return array.slice(Math.max(array.length - n, 0));
      };
      return {
        ...state,
        isTransactionSubmitting: false,
        transactions: [action.payload,...state.transactions],
        lots: [...state.lots, last(action.payload.mouvements).lot]
      }

      case "EDIT_TRANSACTION_SUCCESS":
        const idx = state.transactions.findIndex(t => t.id === action.payload.id);
        const newTrans = Object.assign([], state.transactions);
        newTrans.splice(idx, 1, action.payload);
        let newLots = state.lots
        for (let mvt of action.payload.mouvements){
        const idz = state.lots.findIndex(t => t.id ===mvt.lot.id);
        if (idz===-1){newLots = [...newLots,mvt.lot]}
        }
        return {
          ...state,
          isTransactionSubmitting: false,
          transactions: newTrans,
          lots: newLots,
        }

    // Dig into HomePage 
    case "HOMEDISPLAY":
      return {
        ...state,
        homeDisplay: action.payload,
        headersearch:null
      }
      case "ADD_MOV":
        {

          let lot =  state.lots[state.lots.findIndex(t=>t.id === action.lot)]
          const newCounter = state.counter + 1;
          const newMouvement = {id: newCounter,operationDate: state.transaction.operationDate?state.transaction.operationDate:new Date(),product:action.lot===0?'':lot.product.name,  number: action.lot===0?'':lot.numero, unit: action.lot===0?'':lot.unite, measureUnit: action.lot===0?'':lot.unitemesure, dluo: null, dlc: null ,thirdparty:'', quantity: ''};
          let newTransaction = state.transaction
          newTransaction[action.operation] = [...newTransaction[action.operation],newMouvement]
          return {
            ...state,
            counter: newCounter,
            transaction: newTransaction,
          };
        }
      case "EDIT_MOV":
        {
          let idx = state.transaction[action.operation].findIndex(t => t.id === action.payload.id);
          const newMov = Object.assign([], state.transaction[action.operation]);
          newMov.splice(idx, 1, action.payload);
          
          let newTransaction = state.transaction
          newTransaction[action.operation] = newMov
          newTransaction.operationDate = action.payload.operationDate
          return {
            ...state,
            counter: state.counter,
            transaction: newTransaction,
          };
        }
      case "REMOVE_MOV":
        {
          
          const idx = state.transaction[action.operation].findIndex(t => t.id === action.movId);
          const newMov = Object.assign([], state.transaction[action.operation]);
          newMov.splice(idx, 1);
          let newTransaction = state.transaction
          newTransaction[action.operation] = newMov
          return {
            ...state,
            counter: state.counter,
            transaction: newTransaction,
          };
        }
        case "LOAD_TRANSACTION":
          {
            return {
              ...state,
              transaction: transactionService.loadTransaction(action.payload),
            };
          }
          case "CLEAN_TRANSACTION":
            {
              return {
                ...state,
                transaction: {transactionId:0,achat:[],vente:[]},
                counter:0
              };
            }

    default:
      console.log('REDUCER_ISSUE')
      return state;
  }
};

export default reducer;