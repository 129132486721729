import * as backend from "./backend";

export function addTransaction(transaction, base_url, access_token,dateToYMD,active) {
    let objectid = null
    transaction.transactionId === 0 ? objectid = null : objectid = transaction.transactionId
  
    
    let input = {
        "mouvements": [],
        "operation": transaction.achat.length>0?transaction.vente.length>0?'Transformation':'Achat':'Vente',
        "status": active===true?"active":'inactive',
        "operationdate": dateToYMD(transaction.operationDate)
      }

     console.log(transaction)
    for (let movement of transaction.vente){
    
        let mvt = { id: movement.mouvementId,  lot: { product: { name: movement.product },
            numero: movement.number, unite :movement.unit , unitemesure: movement.measureUnit, dluo: movement.dluo?dateToYMD(movement.dluo):null, dlc: movement.dlc?dateToYMD(movement.dlc):null}, 
            tier: { name: transaction.achat.length>0?'stock':transaction.vente[0].thirdparty }, 
            quantite: movement.quantity} 
        input.mouvements=[...input.mouvements,mvt]
        }

    for (let movement of transaction.achat){
    
        let mvt = { id: movement.mouvementId, lot: { product: { name: movement.product },
            numero: movement.number, unite :movement.unit , unitemesure: movement.measureUnit, dluo: movement.dluo?dateToYMD(movement.dluo):null, dlc: movement.dlc?dateToYMD(movement.dlc):null}, 
            tier: { name: transaction.vente.length>0?'stock':transaction.achat[0].thirdparty }, 
            quantite: movement.quantity} 
        input.mouvements=[...input.mouvements,mvt]
        }
     

    return backend.postdata(base_url, 'transaction', objectid, input, access_token)
}

export function disableTransaction(transaction, base_url, access_token) {
    let input = {"status": "inactive"}
    return backend.postdata(base_url, 'transaction', transaction.transactionId, input, access_token)
}


export function QttperLot(transactions, type, tier, searchLot, reference) {
    let references = [], transaction = []
    let id = 1

    //reference = {product:{id:77}, unite:'1.00',unitemesure:'Kg'}
    for (transaction of transactions) {
        for (const mouvement of transaction.mouvements) {
            if (type === 'thirdparty') {

                if ((tier === 0 || tier === mouvement.tier.id) &&
                    (searchLot === 0 || searchLot === mouvement.lot.id) &&
                    (reference === 0 || (reference.product.id === mouvement.lot.product.id && reference.unite === mouvement.lot.unite && reference.unitemesure === mouvement.lot.unitemesure))) {

                    const idx = references.findIndex(x => x.tier === mouvement.tier.id)
                    if (idx === -1) {
                        references = [{ id: id, reference: mouvement.lot.product.name + ' - ' + mouvement.lot.unite + ' ' + mouvement.lot.unitemesure, tier: mouvement.tier.id, thirdparty: mouvement.tier.name, qttIn: 0, qttOut: 0, remaining: 0,lot: searchLot===0?0:mouvement.lot,numero: searchLot===0?null:mouvement.lot.numero }, ...references]
                        id = id + 1
                    }
                    if (transaction.operation === 'Achat' || (transaction.operation === 'Transformation' && mouvement !== transaction.mouvements.sort((a, b) => (a.id > b.id) ? 1 : -1)[0])) {
                        references[idx === -1 ? 0 : idx].qttIn = Math.round((references[idx === -1 ? 0 : idx].qttIn + Number(mouvement.quantite))*100)/100
                        references[idx === -1 ? 0 : idx].remaining = Math.round((references[idx === -1 ? 0 : idx].remaining + Number(mouvement.quantite))*100)/100
                    }
                    else {
                        references[idx === -1 ? 0 : idx].qttOut = Math.round((references[idx === -1 ? 0 : idx].qttOut - Number(mouvement.quantite))*100)/100
                        references[idx === -1 ? 0 : idx].remaining = Math.round((references[idx === -1 ? 0 : idx].remaining - Number(mouvement.quantite))*100)/100
                    }
                }
            }
            else if (type === 'lot') {
                if (
                    (tier === 0 || tier === mouvement.tier.id) &&
                    (searchLot === 0 || searchLot === mouvement.lot.id) &&
                    (reference === 0 || (reference.product.id === mouvement.lot.product.id && reference.unite === mouvement.lot.unite && reference.unitemesure === mouvement.lot.unitemesure))) {
                    const idx = references.findIndex(x => x.lot.id === mouvement.lot.id)
                    if (idx === -1) {
                        references = [{ id: id, reference: mouvement.lot.product.name + ' - ' + mouvement.lot.unite + ' ' + mouvement.lot.unitemesure,lot: mouvement.lot, numero: mouvement.lot.numero, qttIn: 0, qttOut: 0, remaining: 0 }, ...references]
                        id = id + 1
                    }
                    if (transaction.operation === 'Achat' || (transaction.operation === 'Transformation' && mouvement !== transaction.mouvements.sort((a, b) => (a.id > b.id) ? 1 : -1)[0])) {
                        references[idx === -1 ? 0 : idx].qttIn = Math.round((references[idx === -1 ? 0 : idx].qttIn + Number(mouvement.quantite))*100)/100
                        references[idx === -1 ? 0 : idx].remaining = Math.round((references[idx === -1 ? 0 : idx].remaining + Number(mouvement.quantite))*100)/100
                    }
                    else {
                        references[idx === -1 ? 0 : idx].qttOut = Math.round((references[idx === -1 ? 0 : idx].qttOut - Number(mouvement.quantite))*100)/100
                        references[idx === -1 ? 0 : idx].remaining = Math.round((references[idx === -1 ? 0 : idx].remaining - Number(mouvement.quantite))*100)/100
                    }
                }
            }
            else if (type === 'ref') {
                if ((tier === 0 || tier === mouvement.tier.id) &&
                    (searchLot === 0 || searchLot === mouvement.lot.id) &&
                    (reference === 0 || (reference.product.id === mouvement.lot.product.id && reference.unite === mouvement.lot.unite && reference.unitemesure === mouvement.lot.unitemesure))) {
                    const idx = references.findIndex(x => x.product.id === mouvement.lot.product.id && x.unite === mouvement.lot.unite && x.unitemesure === mouvement.lot.unitemesure)
                    if (idx === -1) {
                        references = [{ id: id, reference: mouvement.lot.product.name + ' - ' + mouvement.lot.unite + ' ' + mouvement.lot.unitemesure, product: mouvement.lot.product, unite: mouvement.lot.unite, unitemesure: mouvement.lot.unitemesure, qttIn: 0, qttOut: 0, remaining: 0 }, ...references]
                        id = id + 1
                    }
                    if (transaction.operation === 'Achat' || (transaction.operation === 'Transformation' && mouvement !== transaction.mouvements.sort((a, b) => (a.id > b.id) ? 1 : -1)[0])) {
                        references[idx === -1 ? 0 : idx].qttIn = Math.round((references[idx === -1 ? 0 : idx].qttIn + Number(mouvement.quantite))*100)/100
                        references[idx === -1 ? 0 : idx].remaining = Math.round((references[idx === -1 ? 0 : idx].remaining + Number(mouvement.quantite))*100)/100
                    }
                    else {
                        references[idx === -1 ? 0 : idx].qttOut = Math.round((references[idx === -1 ? 0 : idx].qttOut - Number(mouvement.quantite))*100)/100
                        references[idx === -1 ? 0 : idx].remaining = Math.round((references[idx === -1 ? 0 : idx].remaining - Number(mouvement.quantite))*100)/100
                    }
                }
            }
            else if (type === 'movements') {
            
                if ((tier === 0 || tier === mouvement.tier.id) &&
                    (searchLot === 0 || searchLot === mouvement.lot.id)) {
                        references = [{ id: id, transactionId:transaction.id, mouvementId:mouvement.id,reference: mouvement.lot.product.name + ' - ' + mouvement.lot.unite + ' ' + mouvement.lot.unitemesure, thirdparty: mouvement.tier.name, quantity: 0, operation: transaction.operation, operationDate:transaction.operationdate, numero:mouvement.lot.numero}, ...references]
                        id= id+1
                        if (transaction.operation === 'Achat' || (transaction.operation === 'Transformation' && mouvement !== transaction.mouvements.sort((a, b) => (a.id > b.id) ? 1 : -1)[0])) {
                            references[0].quantity = Math.round((references[0].quantity + Number(mouvement.quantite))*100)/100
                        }
                        else {
                            references[0].quantity = Math.round((references[0].quantity - Number(mouvement.quantite))*100)/100
                        }
                    }
                }
        
    }}
    //console.log(references,type,searchLot)
    return references
}

export function loadTransaction (transaction){
let mouvement = {}
let newMouvement = {}
let achat = []
let vente = []
let i = 1
let operation = transaction.operation
if (operation === 'Achat') {
  for (mouvement of transaction.mouvements.sort((a, b) => (a.id < b.id) ? 1 : -1)) {
    newMouvement = {
      id: i, 
        mouvementId: mouvement.id,
        product: mouvement.lot.product.name,
        number: mouvement.lot.numero, 
        unit: mouvement.lot.unite,
        measureUnit: mouvement.lot.unitemesure, 
        dluo: mouvement.lot.dluo, 
        dlc: mouvement.lot.dlc,
        thirdparty: mouvement.tier.name, 
        quantity: mouvement.quantite,
        operationDate: transaction.operationdate
    }
    achat = [...achat, newMouvement]
    i = i + 1
  }
}
else if (operation === 'Vente') {
  for (mouvement of transaction.mouvements.sort((a, b) => (a.id < b.id) ? 1 : -1)) {
    newMouvement = {
        id: i,
        mouvementId: mouvement.id,
        product: mouvement.lot.product.name,
        number: mouvement.lot.numero, 
        unit: mouvement.lot.unite,
        measureUnit: mouvement.lot.unitemesure, 
        dluo: mouvement.lot.dluo, 
        dlc: mouvement.lot.dlc,
        thirdparty: mouvement.tier.name, 
        quantity: mouvement.quantite,
        operationDate: transaction.operationdate
    }
    vente = [...vente, newMouvement]
    i = i + 1
  }
}
else if (operation === 'Transformation') {
for (mouvement of transaction.mouvements.sort((a, b) => (a.id < b.id) ? 1 : -1))
if (i !== 1) {
  newMouvement = {
    id: i, 
    mouvementId: mouvement.id,
      product: mouvement.lot.product.name,
      number: mouvement.lot.numero, 
      unit: mouvement.lot.unite,
      measureUnit: mouvement.lot.unitemesure, 
      dluo: mouvement.lot.dluo, 
      dlc: mouvement.lot.dlc,
      thirdparty: mouvement.tier.name, 
      quantity: mouvement.quantite,
      operationDate: transaction.operationdate
  }
  vente = [...vente, newMouvement]

  i = i + 1
}
else if (i === 1) {
  newMouvement= {
      id:i,
      mouvementId: mouvement.id,
      product: mouvement.lot.product.name,
      number: mouvement.lot.numero, 
      unit: mouvement.lot.unite,
      measureUnit: mouvement.lot.unitemesure, 
      dluo: mouvement.lot.dluo, 
      dlc: mouvement.lot.dlc,
      thirdparty: mouvement.tier.name, 
      quantity: mouvement.quantite,
      operationDate: transaction.operationdate
  }
  i = i + 1

  achat = [...achat,newMouvement]
 }
}
return {transactionId: transaction.id, achat:achat, vente:vente, operation: transaction.operation, operationDate: transaction.operationdate}
}

export function addMovement(movements, setMovements){
    console.log(movements)
    const newMovement = {
       id: movements.length+2,
       operationdate: new Date(),
         product:'', 
         number: '',
         unit: '', 
         measureUnit: '', 
         dluo: null, 
         dlc: null ,
         thirdparty:'',
         quantity: '',
     };
     setMovements([...movements,newMovement])

   } 
   
   export function removeMovement (movements, setMovements) {
    movements.pop()
    setMovements(movements)
   }
   


