import React, { useContext } from 'react'
import { AppBar, Toolbar, makeStyles,Avatar, IconButton, Tooltip, Fade } from '@material-ui/core'
import { useStateValue } from "./../../StateProvider";
import { LanguageContext } from '../../LanguageProvider';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#fff',
        flex: 0.1,
        maxHeight: '70px',

    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        color:'black',
    },
    rightSide:{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginLeft: 'auto'
    }

}))

export default function Header() {
    const { d } = useContext(LanguageContext);
    const [{ user }, dispatch] = useStateValue();
    const classes = useStyles();




    return (
        <AppBar position="static" className={classes.root}>
            <Toolbar className={classes.toolbar}>
   <div className={classes.rightSide}>

                    <div>{user.first_name} <Avatar alt={user.first_name} /></div>
                    <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} title={d.disconnect}>
   <IconButton
            variant="outlined"
            fontSize="large"
            onClick={()=>dispatch({type:'LOGOUT'})}
            color="primary"
          >
              <LockOpenOutlinedIcon/>
          </IconButton>
          </Tooltip>
                    </div>
           </Toolbar>
        
        </AppBar>
    )
}
